import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Category } from '../../core/interfaces/category';
import { Place } from '../../core/interfaces/place';

@Injectable()
export class HighlightsAdminService {
  constructor(
  ) {
  }

  getHighlights = (cityId, date): Observable<any[]> => {
    return of([{
      category: 1,
      events: [
        {
          id: 1,
          name: 'Toska'
        }, {
          id: 2,
          name: 'Lazarillo'
        }, {
          id: 3,
          name: 'Barro negro'
        },
      ],
      highlights: []
    }, {
      category: 2,
      events: [
        {
          id: 4,
          name: 'Dos guitarras'
        }, {
          id: 5,
          name: 'Chino'
        }, {
          id: 6,
          name: 'Barro negro'
        },
      ],
      highlights: [{event: 4, payed: true}, {event: 5, payed: false}]
    }]);
  }

  updateHighlights = (cityId, date, form): Observable<void> => {
    return of(undefined);
  }
}
