import { Injectable } from '@angular/core';
import { CanLoad, Router, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AuthorizationService } from '../services/authorization.service';
import { ClientResponse } from '../interfaces/client-response';

@Injectable()
export class ClientGuard  implements CanLoad {
  constructor(private authService: AuthService, private router: Router, private authorizationService: AuthorizationService) {}
  /**
   * You should implement your admin guard logic here
   * @param route 
   * @param segments 
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      if (this.authService.isClientAuthenticated()) {
        return true;
      } else {
        return new Observable<boolean>((observer) => {
          this.authorizationService.loginClient().subscribe((res: ClientResponse) => {
            if (res) {
              localStorage.setItem('access_token', res.access_token);
              observer.next(true);
              observer.complete();
            } else {
              observer.next(false);
              observer.complete();
            }
          });
        });
      }
  }
}
