import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from '../../core/services/api-helper.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EventsParams } from '../../core/interfaces/events-params';
import { EventDetail } from '../../core/interfaces/event-detail';
import { EventsResponse } from '../../core/interfaces/events-response';

@Injectable()
export class EventsAdminService {
  private eventsUrl = '/events';
  constructor(
    @Inject('ApiEndpoint') private apiEndpoint: string,
    private http: HttpClient,
    private helper: ApiHelperService
  ) {
    this.eventsUrl = this.apiEndpoint + this.eventsUrl;
  }

  getEvents = (params: EventsParams): Observable<EventsResponse> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<EventsResponse>(this.eventsUrl + this.gerUrlQueryFromParams(params), options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  getEventDetails = (eventId): Observable<EventDetail> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<EventDetail>(this.eventsUrl + '/' + eventId, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  private gerUrlQueryFromParams(params: EventsParams): string {
    let query = `?city=${params.cityId}&category=${params.categoryId}&limit=${params.limit}`;
    if (params.from)
      query = `${query}&from=${params.from}`;
    if (params.to)
      query = `${query}&to=${params.to}`;
    if (params.sort)
      query = `${query}&sort=${params.sort}`;
    if (params.offset)
      query = `${query}&offset=${params.offset}`;
    if (params.genre)
      query = `${query}&genre=${params.genre}`;
    if (params.search)
      query = `${query}&name=${params.search}`;
    return query;
  }

  createEvent = (eventForm) => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .post<EventDetail>(`${this.eventsUrl}`, eventForm, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  editEvent = (eventForm) => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .put<EventDetail>(`${this.eventsUrl}/${eventForm.id}`, eventForm, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  deleteEvent = (eventId) => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .delete(`${this.eventsUrl}/${eventId}`, options)
      .pipe(catchError(ApiHelperService.handleError));
  }
}
