import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Category } from '../interfaces/category';
import { FacadeService } from '../../public/services/facade.service';

@Injectable()
export class AllCategoriesResolver implements Resolve<any> {
  constructor(private service: FacadeService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Category[]>|Promise<Category[]>|any {
    return this.service.loadAllCategories();
  }
}
