import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CitiesService } from '../../core/services/cities.service';
import { tap } from 'rxjs/operators';
import { EventsParams } from '../../core/interfaces/events-params';
import { Category } from '../../core/interfaces/category';
import { EventsFilters } from '../../core/interfaces/events-filters';
import { CategoriesService } from '../../core/services/categories.service';
import { of } from 'rxjs';
import { SpinnerService } from '@cultus/spinner';
import { PlacesService } from '../../core/services/places.service';

@Injectable()
export class FacadeService {
  currentParams: EventsParams;
  itemsPerLoad = 12;

  constructor(
    private dataService: DataService,
    private citiesService: CitiesService,
    private categoriesService: CategoriesService,
    private placesService: PlacesService,
    private spinnerService: SpinnerService
  ) { 
    this.currentParams = {
      categoryId: 1,
      cityId: 1
    };
  }

  getCities$ = () => {
    return this.dataService.getCities$();
  }

  loadCities = () => {
    return this.citiesService.getCities()
      .pipe(tap(cities => this.dataService.setCities(cities)));
  }

  getCategories$ = () => {
    return this.dataService.getCategories$();
  }

  getHighlights$ = () => {
    return this.dataService.getHighlights$();
  }

  getAllCategories$ = () => {
    return this.dataService.getAllCategories$();
  }

  loadCategories = (id?: number) => {
    const cityId = id ? id : 1; 
    return this.citiesService.getCategories(cityId)
      .pipe(tap(categories => this.dataService.setCategories(categories)));
  }

  loadPlace = (key: string) => {
    return this.placesService.getPlace(key);
  }

  loadCategoriesAndHighlights = (id?: number) => {
    this.spinnerService.start();
    const cityId = id ? id : 1; 
    return this.citiesService.getHighlights(cityId)
      .pipe(tap(categories => {
        this.dataService.setHighlights(categories)
        this.spinnerService.stop();
      }));
  }

  loadAllCategories = () => {
    return this.categoriesService.getCategories()
      .pipe(tap(categories => this.dataService.setAllCategories(categories)));
  }

  getCurrentFilters$ = () => {
    return this.dataService.getCurrentPage$();
  }

  getCurrentPage$ = () => {
    return this.dataService.getCurrentPage$();
  }

  getSubcategories$ = () => {
    return this.dataService.getSubcategories$();
  }

  loadSubcategories = (categoryId: number) => {
    this.dataService.refreshSubcategories(categoryId);
  }

  getCurrentCategory = (): Category => {
    return this.dataService.getCurrentCategory();
  }

  resetSearch = () => {
    this.currentParams = {
      cityId: 1,
      categoryId: 1
    };
  }
}
