import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Category } from '../interfaces/category';
import { CategoryInfo } from '../interfaces/category-info';
import { City } from '../interfaces/city';

@Injectable()
export class CitiesService {
  constructor(
  ) {
  }

  getCities = (): Observable<any[]> => {
    return of([{
      id: 1,
      name: 'Montevideo',
    }, {
      id: 2,
      name: 'Canelones'
    }, {
      id: 3,
      name: 'Maldonado'
    }, {
      id: 4,
      name: 'Colonia'
    }]);
  }

  getActiveCities = (): Observable<City[]> => {
    return of([{
      id: 1,
      name: 'Montevideo',
    }, {
      id: 2,
      name: 'Canelones'
    }]);
  }

  getCitiesWithPlaces = (): Observable<City[]> => {
    return of([{
      id: 1,
      name: 'Montevideo',
    }, {
      id: 2,
      name: 'Canelones'
    }, {
      id: 4,
      name: 'Colonia'
    }]);
  }

  getCategories = (id): Observable<Category[]> => {
    return of([{
      id: 1,
      name: id === 2 ? 'Teatro' : 'Danza',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/coolturus-uy.appspot.com/o/categories%2Fjonatan-moerman-4nUQH0EXzfg-unsplash.jpg?alt=media&token=efa23cb1-202b-4f3b-9c0e-e153eb1b38e8',
      info: 'Teatro, stand up, impro, etc.',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 2,
      name: 'Musica en vivo',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/coolturus-uy.appspot.com/o/categories%2Fbantersnaps-tkTAZM7m238-unsplash.jpg?alt=media&token=20e3cb89-f9b8-4709-975d-77d28c878c02',
      info: 'Conciertos, toques, cena show, etc.',
      genres: [{id: 4, name: 'Rock'}, {id: 5, name: 'Plena'}, {id: 6, name: 'Blues'}]
    }, {
      id: 3,
      name: 'Circo',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/coolturus-uy.appspot.com/o/categories%2Fnaitian-tony-wang-rT6dZ6qYeeU-unsplash.jpg?alt=media&token=d2a594f4-2a50-4499-ac23-cf50fc3c5065',
      info: 'Circo, bla bla bla.',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 4,
      name: 'Karaoke',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/karaoke_cat.jpg',
      info: 'Karaoke, open mic, etc.',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 5,
      name: 'Magia',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/magic_cat.jpg',
      info: 'Shows de magia, ilusionismo, etc.',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }]);
  }

  getHighlights = (cityId: number): Observable<CategoryInfo[]> => {
    return of([{
      id: 1,
      name: cityId === 2 ? 'Teatro' : 'Danza',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/coolturus-uy.appspot.com/o/categories%2Fjonatan-moerman-4nUQH0EXzfg-unsplash.jpg?alt=media&token=efa23cb1-202b-4f3b-9c0e-e153eb1b38e8',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}],
      highlights: [
        {
          id: 1,
          name: 'Toska',
          nextFunction: new Date(),
          genre: {
            name: 'Drama',
            class: 'drama'
          },
          imageUrl: 'https://firebasestorage.googleapis.com/v0/b/coolturus-uy.appspot.com/o/flyers%2Fexacmple-flyer.jpeg?alt=media&token=35a7c35a-c894-42e8-a434-21bd73d34bd7',
          functions: 2,
          place: 'Teatro del Notariado',
          info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
        }, {
          id: 2,
          name: 'Rescatate',
          nextFunction: new Date(),
          genre: {
            name: 'Accion',
            class: 'action'
          },
          imageUrl: 'https://firebasestorage.googleapis.com/v0/b/coolturus-uy.appspot.com/o/flyers%2FEllasbailan1611507646837?alt=media&token=07b68202-4339-43c6-83de-642147978f77',
          functions: 1,
          place: 'Teatro del Notariado',
          info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
        }, {
          id: 3,
          name: 'The tempest',
          nextFunction: new Date(),
          genre: {
            name: 'Comedia',
            class: 'comedy'
          },
          functions: 2,
          place: 'Teatro el Galpon',
          info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
        }
      ]
    }, {
      id: 2,
      name: 'Musica en vivo',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/coolturus-uy.appspot.com/o/categories%2Fbantersnaps-tkTAZM7m238-unsplash.jpg?alt=media&token=20e3cb89-f9b8-4709-975d-77d28c878c02',
      genres: [{id: 4, name: 'Rock'}, {id: 5, name: 'Plena'}, {id: 6, name: 'Blues'}]
    }, {
      id: 3,
      name: 'Circo',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/coolturus-uy.appspot.com/o/categories%2Fnaitian-tony-wang-rT6dZ6qYeeU-unsplash.jpg?alt=media&token=d2a594f4-2a50-4499-ac23-cf50fc3c5065',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 4,
      name: 'Karaoke',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/karaoke_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 5,
      name: 'Magia',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/magic_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }]);
  }
}
