import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Category } from '../../core/interfaces/category';

@Injectable()
export class CategoriesAdminService {
  constructor(
  ) {
  }

  getCategories = (): Observable<Category[]> => {
    return of([{
      id: 1,
      name: 'Teatro',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/theater_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 2,
      name: 'Musica en vivo',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/live_music_cat.jpg',
      genres: [{id: 4, name: 'Rock'}, {id: 5, name: 'Plena'}, {id: 6, name: 'Blues'}]
    }, {
      id: 3,
      name: 'Circo',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/circus_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 4,
      name: 'Karaoke',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/karaoke_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 5,
      name: 'Magia',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/magic_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }]);
  }

  getCategory = (id): Observable<Category> => {
    return of({
      id: 1,
      name: 'Teatro',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/theater_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    });
  }

  createCategory = (category) => {
    return of({
      id: 1,
      name: 'Teatro',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/theater_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    });
  }

  updateCategory = (category) => {
    return of(category);
  }

  addGenreCategory = (categoryId, genre) => {
    return of({id: 1, name: 'Impro'});
  }

  updateGenreCategory = (categoryId, genre) => {
    return of(genre);
  }
}
