import { Injectable } from '@angular/core';
import { DataAdminService } from './data-admin.service';
import { CitiesService } from '../../core/services/cities.service';
import { tap } from 'rxjs/operators';
import { EventsParams } from '../../core/interfaces/events-params';
import { Category } from '../../core/interfaces/category';
import { EventsFilters } from '../../core/interfaces/events-filters';
import { of, Observable } from 'rxjs';
import { CategoriesAdminService } from './categories-admin.service';
import { Genre } from '../../core/interfaces/genre';
import { EventsAdminService } from './events-admin.service';
import { ScrappingDetail } from '../../core/interfaces/scrapping-data';

@Injectable()
export class FacadeAdminService {
  currentParams: EventsParams;
  itemsPerLoad = 12;
  currentSuggestion = null;
  currentScrapping: ScrappingDetail = null;

  constructor(
    private dataService: DataAdminService,
    private eventsService: EventsAdminService,
    private citiesService: CitiesService,
    private categoriesService: CategoriesAdminService
  ) { 
    this.currentParams = {
      categoryId: 1,
      cityId: 1
    };
  }

  getCities$ = () => {
    return this.dataService.getCities$();
  }

  loadCities = () => {
    return this.citiesService.getCities()
      .pipe(tap(cities => this.dataService.setCities(cities)));
  }

  getCitiesWithPlaces$ = () => {
    return this.dataService.getCitiesWithPlaces$();
  }

  loadCitiesWithPlaces = () => {
    return this.citiesService.getCitiesWithPlaces()
      .pipe(tap(cities => this.dataService.setCitiesWithPlaces(cities)));
  }

  getActiveCities$ = () => {
    return this.dataService.getActiveCities$();
  }

  loadActiveCities = () => {
    return this.citiesService.getActive()
      .pipe(tap(cities => this.dataService.setActiveCities(cities)));
  }

  getAllCategories$ = () => {
    return this.dataService.getAllCategories$();
  }

  loadAllCategories = () => {
    return this.categoriesService.getCategories()
      .pipe(tap(categories => this.dataService.setAllCategories(categories)));
  }

  getEvents$ = () => {
    return this.dataService.getEvents$();
  }

  getEventsCount$ = () => {
    return this.dataService.getEventsCount$();
  }

  getEventsRetrived$ = () => {
    return this.dataService.getEventsRetrived$();
  }

  loadEvents = (params: EventsParams) => {
    if (this.currentParams.cityId !== params.cityId || this.currentParams.categoryId !== params.categoryId) {
      this.currentParams.categoryId = params.categoryId;
      this.currentParams.cityId = params.cityId;
      this.currentParams.limit = this.itemsPerLoad;
      this.currentParams.offset = 0;
      this.dataService.setCurrentPage(1);
      return this.retriveEvents();
    } else {
      return of({});
    }
  }

  goToPage = (page: number) => {
    this.currentParams.offset = (page - 1) * this.itemsPerLoad;
    this.dataService.setCurrentPage(page);
    this.retriveEvents().subscribe(() => {});
  }

  getCurrentFilters$ = () => {
    return this.dataService.getCurrentPage$();
  }

  getCurrentPage$ = () => {
    return this.dataService.getCurrentPage$();
  }

  loadMoreEvents = () => {
    this.currentParams.offset = this.currentParams.offset + this.itemsPerLoad;
    return this.eventsService.getEvents(this.currentParams)
      .pipe(tap(events => this.dataService.addEvents(events)));
  }

  addFilters = (filters: EventsFilters) => {
    this.currentParams.from = filters.from;
    this.currentParams.to = filters.to;
    this.currentParams.genre = filters.genre;
    this.currentParams.offset = 0;
    return this.retriveEvents();
  }

  addTerm = (term) => {
    this.currentParams.search = term;
    this.currentParams.offset = 0;
    return this.retriveEvents();
  }

  sortEvents = (sort: string) => {
    this.currentParams.order = sort;
    this.currentParams.offset = 0;
    return this.retriveEvents();
  }

  retriveEvents = () => {
    return this.eventsService.getEvents(this.currentParams)
      .pipe(tap(events => this.dataService.setEvents(events)));
  }

  getSubcategories$ = () => {
    return this.dataService.getSubcategories$();
  }

  loadSubcategories = (categoryId: number) => {
    this.dataService.refreshSubcategories(categoryId);
  }

  getCurrentCategory = (): Category => {
    return this.dataService.getCurrentCategory();
  }

  resetSearch = () => {
    this.currentParams = {
      cityId: 1,
      categoryId: 1
    };
  }

  createCategory = (category: Category): Observable<Category> => {
    return this.categoriesService.createCategory(category);
  }

  updateCategory = (category: Category): Observable<Category> => {
    return this.categoriesService.updateCategory(category);
  }

  createGenre = (categoryId: number, genre: Genre): Observable<Genre> => {
    return this.categoriesService.addGenreCategory(categoryId, genre);
  }

  updateGenre = (categoryId: number, genre: Genre): Observable<Genre> => {
    return this.categoriesService.updateGenreCategory(categoryId, genre);
  }

  setCurrentSuggestion = (suggestion) => {
    this.currentSuggestion = suggestion;
  }

  getCurrentSuggestion = () => {
    return this.currentSuggestion;
  }

  removeCurrentSuggestion = () => {
    this.currentSuggestion = null;
  }

  setCurrentScrapping = (scrapping) => {
    this.currentScrapping = scrapping;
  }

  getCurrentScrapping = () => {
    return this.currentScrapping;
  }

  removeCurrentScrapping = () => {
    this.currentScrapping = null;
  }
}
