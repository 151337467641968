import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, Event } from '@angular/router';
import { SpinnerService } from '@cultus/spinner';

@Component({
  selector: 'cultus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angular-template';

  constructor(
    private router: Router,
    private spinnerService: SpinnerService
  ) {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.spinnerService.secureStart();
      }

      if (event instanceof NavigationEnd) {
        this.spinnerService.stop();
      }

      if (event instanceof NavigationError) {
          this.spinnerService.stop();

          // Present error to user
          console.log(event.error);
      }
    });
  }
}
