import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Category } from '../../core/interfaces/category';
import { Place } from '../../core/interfaces/place';
import { ScrappingDetail } from '../../core/interfaces/scrapping-data';

@Injectable()
export class ScrappingAdminService {
  constructor(
  ) {
  }

  getScrapping = (cityId, date): Observable<ScrappingDetail[]> => {
    return of([{
      docId: "cartelera-id-18307",
      status: "new",
      sourceUrl: "https://www.cartelera.com.uy/averespectaculo.aspx?18307",
      source: "cartelera",
      name: "Ser humana",
      info: "Una pieza escrita y creada en honor a la memoria de Sabina Spielrein (1885-1942), rescatando palabras de su diario, su correspondencia y su teoría. Ella atravesó la locura y habitó profundos delirios. Comenzó a estudiar psicología mientras estaba internada, y se apasionó de tal forma que comprendió incluso el funcionamiento de su enfermedad, colaborando activamente con su sanación. Ingresó a la Universidad de Zurich y se especializó en psicología y psiquiatría. Luego desarrolló aportes teóricos fundamentales para ambas áreas. Sin embargo, su historia estuvo oculta durante muchos años...",
      genre: "Unipersonal",
      category: 1,
      duration: "90'",
      place: {},
      tickets: [
          "$ 550. En venta en Abitab."
      ],
      moreData: [
          {
              header: "Elenco",
              text: "Angie Oña"
          },
          {
              header: "Dirige",
              text: "Angie Oña"
          }
      ]
  },
  {
      docId: "cartelera-id-23352",
      status: "new",
      sourceUrl: "https://www.cartelera.com.uy/averespectaculo.aspx?23352",
      source: "cartelera",
      name: "Laura Falero Stand up",
      info: "En un pasaje por sus diez años haciendo humor, Laura nos trae nuevas premisas, chistes nuevos y alguna sorpresa musical, anticipando algunas temáticas de su nuevo unipersonal a estrenar en 2022. Una noche de humor irónico, ácido, reflexivo y post pandémico, en un renacer humorístico donde lejos de ser catártico celebra y reflexiona sobre sus 40 años de vida.",
      genre: "Stand up",
      category: 1,
      duration: "",
      place: {},
      tickets: [
          "$ 300. Reservas por Whatsapp 099581053."
      ],
      moreData: [
          {
              header: "Elenco",
              text: "Laura Falero"
          },
          {
              header: "Dirige",
              text: "Laura Falero"
          }
      ]
  }]);
  }
}
