import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { ClientResponse } from '../interfaces/client-response';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

@Injectable()
export class AuthorizationService {
  user: Observable<firebase.User>;

  constructor(
    private firebaseAuth: AngularFireAuth
  ) {
    this.user = this.firebaseAuth.authState;
  }

  loginClient = (): Observable<ClientResponse> => {
    return of({
      access_token: 'jwt'
    })
  }

  getAuthorizationHeader = () => {
    return 'Bearer ' + localStorage.getItem('access_token');
  }

  logout = () => {
    return this.firebaseAuth.signOut().then(() => {
      localStorage.removeItem('adminJwt');
      localStorage.removeItem('access_token');
    })
  }

  loginAdmin = async (email, password) => {
    const credentials = await this.loginToFirebase(email, password);
    const idToken = await (await this.firebaseAuth.currentUser).getIdToken();
    return of({
      access_token: 'jwt'
    })
  }

  loginToFirebase = (email, password) => {
    return this.firebaseAuth
      .signInWithEmailAndPassword(email, password)
  }
}
