import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Category } from '../interfaces/category';
import { EventsParams } from '../interfaces/events-params';
import { Subcategory } from '../interfaces/subcategory';

@Injectable()
export class CategoriesService {
  private categoriesUrl = '/categories';

  constructor(
    @Inject('ApiEndpoint') private apiEndpoint: string,
    private http: HttpClient,
    private helper: ApiHelperService
  ) {
    this.categoriesUrl = this.apiEndpoint + this.categoriesUrl;
  }

  getCategories = (): Observable<Category[]> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<Category[]>(this.categoriesUrl, options)
      .pipe(catchError(ApiHelperService.handleError));
  }
}
