import { Injectable } from '@angular/core';
import { City } from '../../core/interfaces/city';
import { BehaviorSubject } from 'rxjs';
import { Category } from '../../core/interfaces/category';
import { Subcategory } from '../../core/interfaces/subcategory';
import { Event } from '../../core/interfaces/event';
import { EventsResponse } from '../../core/interfaces/events-response';
import { CategoryInfo } from '../../core/interfaces/category-info';

@Injectable()
export class DataService {
  citiesSubject$ = new BehaviorSubject<City[]>(null);
  categoriesSubject$ = new BehaviorSubject<Category[]>(null);
  highlightsSubject$ = new BehaviorSubject<CategoryInfo[]>(null);
  allCategoriesSubject$ = new BehaviorSubject<Category[]>(null);
  currentCategories = [];
  currentCategoryId: number;
  subcategoryLoaded: boolean;
  subcategoriesSubject$ = new BehaviorSubject<Subcategory[]>(null);
  eventsSubject$ = new BehaviorSubject<Event[]>(null);
  eventsCount$ = new BehaviorSubject<number>(null);
  eventsRetrived$ = new BehaviorSubject<number>(null);
  currentPageSubject$ = new BehaviorSubject<number>(1);
  private eventsRetrived = 0;

  constructor() { }

  getCities$ = () => {
    return this.citiesSubject$.asObservable();
  }

  setCities(cities: City[]) {
    this.citiesSubject$.next(cities);
  }

  getCategories$ = () => {
    return this.categoriesSubject$.asObservable();
  }

  getHighlights$ = () => {
    return this.highlightsSubject$.asObservable();
  }

  getAllCategories$ = () => {
    return this.allCategoriesSubject$.asObservable();
  }

  getSubcategories$ = () => {
    return this.subcategoriesSubject$.asObservable();
  }

  setCategories(categories: Category[]) {
    this.categoriesSubject$.next(categories);
    this.currentCategories = categories;
    if (!this.subcategoryLoaded && this.currentCategoryId)
      this.refreshSubcategories(this.currentCategoryId);
  }

  setHighlights(highlights: CategoryInfo[]) {
    this.highlightsSubject$.next(highlights);
    //TODO: Verify if is useless and remove
    /* if (highlights && highlights.length) {
      this.categoriesSubject$.next(highlights);
      this.currentCategories = highlights;
    }
    if (!this.subcategoryLoaded && this.currentCategoryId)
      this.refreshSubcategories(this.currentCategoryId); */
  }

  setAllCategories(categories: Category[]) {
    this.allCategoriesSubject$.next(categories);
    this.currentCategories = categories;
    if (!this.subcategoryLoaded && this.currentCategoryId)
      this.refreshSubcategories(this.currentCategoryId);
  }

  getEvents$ = () => {
    return this.eventsSubject$.asObservable();
  }

  setEvents(eventsResponse: EventsResponse) {
    this.eventsSubject$.next(eventsResponse.items);
    this.eventsCount$.next(eventsResponse.totalCount);
    this.eventsRetrived = eventsResponse.items.length;
    this.eventsRetrived$.next(this.eventsRetrived);
  }

  getEventsCount$ = () => {
    return this.eventsCount$.asObservable();
  }

  getEventsRetrived$ = () => {
    return this.eventsRetrived$.asObservable();
  }

  addEvents(eventsResponse: EventsResponse) {
    const currentValue = this.eventsSubject$.getValue();
    this.eventsRetrived = this.eventsRetrived + eventsResponse.items.length;
    this.eventsRetrived$.next(this.eventsRetrived);
    this.eventsCount$.next(eventsResponse.totalCount);
    this.eventsSubject$.next([...currentValue, ...eventsResponse.items]);
  }

  refreshSubcategories(categoryId: number) {
    this.currentCategoryId = categoryId;
    const category: Category = this.currentCategories.find(c => c.id === categoryId);
    if (category) {
      this.subcategoriesSubject$.next(category.genres);
      this.subcategoryLoaded = true;
    }
  }

  getCurrentCategory(): Category {
    return this.currentCategories.find(c => c.id === this.currentCategoryId);
  }

  getCurrentPage$ = () => {
    return this.currentPageSubject$.asObservable();
  }

  setCurrentPage(page: number) {
    this.currentPageSubject$.next(page);
  }
}
