import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Subject, Observable, empty, BehaviorSubject } from 'rxjs';
import { Injector, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from './services/authorization.service';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { SpinnerService } from '@cultus/spinner';
import { ClientResponse } from './interfaces/client-response';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  authService;
  refreshTokenInProgress = false;
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<ClientResponse> = new BehaviorSubject<ClientResponse>(null);
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private injector: Injector, private router: Router, private spinnerService: SpinnerService) {}

  addAuthHeader(request) {
    const authHeader = this.authService.getAuthorizationHeader();
    if (authHeader) {
      return request.clone({
        setHeaders: {
          "Authorization": authHeader
        }
      });
    }
    return request;
  }

  refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.loginClient()
        .subscribe((res) => {
          if (res) {
              localStorage.setItem('access_token', res.access_token);
            }
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        });
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["login"]);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // this.spinnerService.start();
    this.authService = this.injector.get(AuthorizationService);

    // Handle request
    request = this.addAuthHeader(request);

    // Handle response
    return next.handle(request).pipe(catchError(error => {

      if (error.status === 401) {
        return this.handleUnauthorized(request, next);
      }

      return Observable.throw(error);
    }),
    finalize(() => {
      // this.spinnerService.stop();
      
    }));
  }

  handleUnauthorized (req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      // get a new token via userService.refreshToken
      return this.authService.loginClient()
        .pipe(switchMap((newToken: ClientResponse) => {
          // did we get a new token retry previous request
          if (newToken) {
            localStorage.setItem('access_token', newToken.access_token);
            this.tokenSubject.next(newToken);
            req = this.addAuthHeader(req);
            return next.handle(req);
          }

          // If we don't get a new token, we are in trouble so logout.
          this.logout();
          return Observable.throw('');
        })
        , catchError(error => {
            // If there is an exception calling 'refreshToken', bad news so logout.
            this.logout();
            return Observable.throw(error);
        })
        , finalize(() => {
            this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject
        .pipe(
          filter(token => token != null)
          , take(1)
          , switchMap((token: ClientResponse) => {
            req = this.addAuthHeader(req);
            return next.handle(req);
          })
        );
    }
  }
}
