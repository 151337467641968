import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './core/guards/admin.guard';
import { ClientGuard } from './core/guards/client.guard';
// import { CategoriesResolver } from './core/resolvers/categories.resolver';
// import { CitiesResolver } from './core/resolvers/cities.resolver';


const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [AdminGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  { 
    path: '', 
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
    canLoad: [ClientGuard],
    resolve: {
      // cities: CitiesResolver,
      // categories: CategoriesResolver
    }
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
