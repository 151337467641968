import { NgModule } from '@angular/core';
import { AdminGuard } from './guards/admin.guard';
import { AuthService } from './services/auth.service';
import { SpinnerService } from '@cultus/spinner';
import { environment } from '../../environments/environment';
import { ApiHelperService } from './services/api-helper.service';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { authorizationServiceFactory, citiesServiceFactory, categoriesServiceFactory, placesServiceFactory } from '../services-factory';
import { AuthInterceptor } from './auth-interceptor';
import { ClientGuard } from './guards/client.guard';
import { AuthorizationService } from './services/authorization.service';
import { CitiesResolver } from './resolvers/cities.resolver';
import { CategoriesResolver } from './resolvers/categories.resolver';
import { CitiesService } from './services/cities.service';
import { DataService } from '../public/services/data.service';
import { FacadeService } from '../public/services/facade.service';
import { AllCategoriesResolver } from './resolvers/all-categories.resolver';
import { CategoriesService } from './services/categories.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActiveCitiesResolver } from '../admin/resolvers/active-cities.resolver';
import { AvailableCitiesResolver } from '../admin/resolvers/available-cities.resolver';
import { PlacesService } from './services/places.service';

@NgModule({
  providers: [
    AdminGuard,
    ClientGuard,
    AuthService,
    SpinnerService,
    ApiHelperService,
    CitiesResolver,
    ActiveCitiesResolver,
    AvailableCitiesResolver,
    CategoriesResolver,
    AllCategoriesResolver,
    FacadeService,
    DataService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: 'ApiEndpoint', useValue: environment.apiEndpoint },
    {
      provide: AuthorizationService,
      useFactory: authorizationServiceFactory,
      deps: ['ApiEndpoint', HttpClient, ApiHelperService, AngularFireAuth]
    },
    {
      provide: CitiesService,
      useFactory: citiesServiceFactory,
      deps: ['ApiEndpoint', HttpClient, ApiHelperService]
    },
    {
      provide: CategoriesService,
      useFactory: categoriesServiceFactory,
      deps: ['ApiEndpoint', HttpClient, ApiHelperService]
    },
    {
      provide: PlacesService,
      useFactory: placesServiceFactory,
      deps: ['ApiEndpoint', HttpClient, ApiHelperService]
    }
  ],
})
export class CoreModule { }
