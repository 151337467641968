import { HttpClient } from '@angular/common/http';
import { CategoriesService } from './core/services/categories-mock.service';
import { AuthorizationService } from './core/services/authorization-mock.service';
import { CitiesService } from './core/services/cities-mock.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ApiHelperService } from './core/services/api-helper.service';
import { EventsAdminService } from './admin/services/events-admin-mock.service';
import { CategoriesAdminService } from './admin/services/categories-admin-mock.service';
import { CitiesAdminService } from './admin/services/cities-admin-mock.service';
import { PlacesAdminService } from './admin/services/places-admin-mock.service';
import { HighlightsAdminService } from './admin/services/highlights-admin-mock.service';
import { PlacesService } from './core/services/places-mock.service';
import { ScrappingAdminService } from './admin/services/scrapping-admin-mock.service';

export const categoriesServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new CategoriesService();
};

export const placesServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new PlacesService();
};

export const authorizationServiceFactory = (apiEndpoint: string, http: HttpClient, helper: ApiHelperService, firebaseAuth: AngularFireAuth) => {
  return new AuthorizationService(firebaseAuth);
};

export const citiesServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new CitiesService();
};

export const eventsAdminServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new EventsAdminService();
};

export const categoriesAdminServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new CategoriesAdminService();
};

export const citiesAdminServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new CitiesAdminService();
};

export const placesAdminServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new PlacesAdminService();
};

export const highlightsAdminServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new HighlightsAdminService();
};

export const scrappingAdminServiceFactory = (apiEndpoint: string, http: HttpClient) => {
  return new ScrappingAdminService();
};
