import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable()
export class ApiHelperService {

  constructor() { }

  static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      if (error.status === 401) {

      }
      
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later. If the problem happens again call IT support.'
    );
  }

  getHttpOptions(): HttpOptions {
    return {
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };
  }

  getHttpAuthOptions(): HttpOptions {
    return {
      headers: new HttpHeaders({
        Accept: 'application/json',
      })
    };
  }
}

export interface HttpOptions {
  headers: HttpHeaders;
}

