import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { City } from '../interfaces/city';
import { Category } from '../interfaces/category';
import { CategoryInfo } from '../interfaces/category-info';

@Injectable()
export class CitiesService {
  private citiesUrl = '/cities';
  constructor(
    @Inject('ApiEndpoint') private apiEndpoint: string,
    private http: HttpClient,
    private helper: ApiHelperService
  ) {
    this.citiesUrl = this.apiEndpoint + this.citiesUrl;
  }

  getCities = (): Observable<City[]> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<City[]>(this.citiesUrl, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  getActive = (): Observable<City[]> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<City[]>(this.citiesUrl + '/active', options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  getCitiesWithPlaces = (): Observable<City[]> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<City[]>(this.citiesUrl + '/available', options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  getCategories = (cityId: number): Observable<Category[]> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<Category[]>(`${this.citiesUrl}/${cityId}/categories`, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  getHighlights = (cityId: number): Observable<CategoryInfo[]> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<CategoryInfo[]>(`${this.citiesUrl}/${cityId}/highlights`, options)
      .pipe(catchError(ApiHelperService.handleError));
  }
}
