import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiHelperService } from './api-helper.service';
import { Category } from '../interfaces/category';
import { Subcategory } from '../interfaces/subcategory';
import { Place } from '../interfaces/place';

@Injectable()
export class PlacesService {
  constructor(
  ) {
  }

  getPlace = (key): Observable<Place> => {
    return of({
      id: 1,
      name: 'Teatro Solis',
      address: 'Buenos Aires',
    });
  }
}
