import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientResponse } from '../interfaces/client-response';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { async } from '@angular/core/testing';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthorizationService {
  private authUrl = '/auth';
  user: Observable<firebase.User>;

  constructor(
    @Inject('ApiEndpoint') private apiEndpoint: string,
    private http: HttpClient,
    private helper: ApiHelperService,
    private firebaseAuth: AngularFireAuth
  ) {
    this.authUrl = this.apiEndpoint + this.authUrl;
    this.user = this.firebaseAuth.authState;
  }

  loginClient = (): Observable<ClientResponse> => {
    const options = this.helper.getHttpOptions();
    return this.http
    .post<ClientResponse>(
      this.authUrl + `/client`,
      {
        client_id: 'angular-app',
        client_secret: environment.apiSecret
      },
      options
    )
    .pipe(catchError(ApiHelperService.handleError));
  }

  getAuthorizationHeader = () => {
    return 'Bearer ' + localStorage.getItem('access_token');
  }

  logout = () => {
    return this.firebaseAuth.signOut().then(() => {
      localStorage.removeItem('adminJwt');
      localStorage.removeItem('access_token');
    })
  }

  loginAdmin = async (email, password) => {
    const credentials = await this.loginToFirebase(email, password);
    const idToken = await (await this.firebaseAuth.currentUser).getIdToken()
    
    return this.loginToBackend(email, idToken).toPromise();
  }

  loginToFirebase = (email, password) => {
    return this.firebaseAuth
      .signInWithEmailAndPassword(email, password)
  }

  loginToBackend = (email, password) => {
    const options = this.helper.getHttpOptions();
    return this.http
    .post<ClientResponse>(
      this.authUrl + `/login`,
      {
        username: email,
        password: password,
        client_id: 'angular-app',
        client_secret: environment.apiSecret
      },
      options
    )
    .pipe(catchError(ApiHelperService.handleError));
  }
}
