import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { City } from '../../core/interfaces/city';

@Injectable()
export class CitiesAdminService {
  constructor(
  ) {
  }

  getCities = (): Observable<City[]> => {
    return of([{
      id: 1,
      name: 'Montevideo',
    }, {
      id: 2,
      name: 'Canelones'
    }]);
  }

  createCity = (city: City) => {
    return of(city);
  }

  updateCity = (city: City) => {
    return of(city);
  }
}
