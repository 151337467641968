import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Place } from '../interfaces/place';

@Injectable()
export class PlacesService {
  private placesUrl = '/places';

  constructor(
    @Inject('ApiEndpoint') private apiEndpoint: string,
    private http: HttpClient,
    private helper: ApiHelperService
  ) {
    this.placesUrl = this.apiEndpoint + this.placesUrl;
  }

  getPlace = (key): Observable<Place> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<Place>(this.placesUrl + '/key/' + key, options)
      .pipe(catchError(ApiHelperService.handleError));
  }
}
