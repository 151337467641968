import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { City } from '../../core/interfaces/city';
import { FacadeAdminService } from '../services/facade-admin.service';

@Injectable()
export class ActiveCitiesResolver implements Resolve<any> {
  constructor(private service: FacadeAdminService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<City[]>|Promise<City[]>|any {
    return this.service.loadActiveCities();
  }
}
