import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

  constructor() { }

  /**
   * Should implement your real logic here
   */
  isAuthenticated(): boolean {
    const token = localStorage.getItem('adminJwt');
    return token ? true : false;
  }

  isClientAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    return token ? true : false;
  }
}
