import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from '../../core/services/api-helper.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Category } from '../../core/interfaces/category';
import { EventsParams } from '../../core/interfaces/events-params';
import { Subcategory } from '../../core/interfaces/subcategory';
import { Genre } from '../../core/interfaces/genre';

@Injectable()
export class CategoriesAdminService {
  private categoriesUrl = '/categories';

  constructor(
    @Inject('ApiEndpoint') private apiEndpoint: string,
    private http: HttpClient,
    private helper: ApiHelperService
  ) {
    this.categoriesUrl = this.apiEndpoint + this.categoriesUrl;
  }

  getCategories = (): Observable<Category[]> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<Category[]>(this.categoriesUrl, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  getCategory = (id): Observable<Category> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .get<Category>(`${this.categoriesUrl}/${id}`, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  createCategory = (category): Observable<Category> => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .post<Category>(`${this.categoriesUrl}`, category, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  updateCategory = (category) => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .put<Category>(`${this.categoriesUrl}/${category.id}`, category, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  addGenreCategory = (categoryId, genre) => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .post<Genre>(`${this.categoriesUrl}/${categoryId}/genres`, genre, options)
      .pipe(catchError(ApiHelperService.handleError));
  }

  updateGenreCategory = (categoryId, genre) => {
    const options = this.helper.getHttpAuthOptions();
    return this.http
      .put<Genre>(`${this.categoriesUrl}/${categoryId}/genres/${genre.id}`, genre, options)
      .pipe(catchError(ApiHelperService.handleError));
  }
}
