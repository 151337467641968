import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EventDetail } from '../../core/interfaces/event-detail';
import { EventsParams } from '../../core/interfaces/events-params';
import { EventsResponse } from '../../core/interfaces/events-response';

@Injectable()
export class EventsAdminService {
  constructor(
  ) {
  }

  getEvents = (params: EventsParams): Observable<EventsResponse> => {
    return of({
      firstIndex: params.offset,
      totalCount: 36,
      items: [{
        id: 1,
        name: 'Toska',
        nextFunction: new Date(),
        genre: {
          name: 'Drama',
          class: 'drama'
        },
        functions: 2,
        place: 'Teatro del Notariado',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 2,
        name: 'Rescatate',
        nextFunction: new Date(),
        genre: {
          name: 'Accion',
          class: 'action'
        },
        functions: 0,
        place: 'Teatro del Notariado',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 3,
        name: 'The tempest',
        nextFunction: new Date(),
        genre: {
          name: 'Comedia',
          class: 'comedy'
        },
        functions: 2,
        place: 'Teatro el Galpon',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 4,
        name: 'El lazarillo de Tormes',
        nextFunction: new Date(),
        genre: {
          name: 'Comedia',
          class: 'comedy'
        },
        functions: 2,
        place: 'Teatro Solis',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 5,
        name: 'Toska',
        nextFunction: new Date(),
        genre: {
          name: 'Drama',
          class: 'drama'
        },
        functions: 2,
        place: 'Teatro del Notariado',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 6,
        name: 'Rescatate',
        nextFunction: new Date(),
        genre: {
          name: 'Accion',
          class: 'action'
        },
        functions: 0,
        place: 'Teatro del Notariado',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 7,
        name: 'The tempest',
        nextFunction: new Date(),
        genre: {
          name: 'Comedia',
          class: 'comedy'
        },
        functions: 2,
        place: 'Teatro el Galpon',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 8,
        name: 'El lazarillo de Tormes',
        nextFunction: new Date(),
        genre: {
          name: 'Comedia',
          class: 'comedy'
        },
        functions: 2,
        place: 'Teatro Solis',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 9,
        name: 'Toska',
        nextFunction: new Date(),
        genre: {
          name: 'Drama',
          class: 'drama'
        },
        functions: 2,
        place: 'Teatro del Notariado',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 10,
        name: 'Rescatate',
        nextFunction: new Date(),
        genre: {
          name: 'Accion',
          class: 'action'
        },
        functions: 0,
        place: 'Teatro del Notariado',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 11,
        name: 'The tempest',
        nextFunction: new Date(),
        genre: {
          name: 'Comedia',
          class: 'comedy'
        },
        functions: 2,
        place: 'Teatro el Galpon',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }, {
        id: 12,
        name: 'El lazarillo de Tormes',
        nextFunction: new Date(),
        genre: {
          name: 'Comedia',
          class: 'comedy'
        },
        functions: 2,
        place: 'Teatro Solis',
        info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...'
      }]
    });
  }

  getEventDetails = (eventId): Observable<any> => {
    return of({
      id: eventId,
      name: 'El lazarillo de Tormes',
      duration: '180 minutes',
      genre: {
        name: 'Comedia',
        class: 'comedy'
      },
      functions: [
        '2020-11-09T18:46',
        '2020-11-19T18:46',
        '2020-11-20T18:46',
        '2020-11-29T18:46',
        '2020-12-10T18:46',
        '2020-12-11T18:46',
        '2020-12-19T18:46'
      ],
      place: {
        id: 1,
        name: 'Teatro Solis',
        address: 'Buenos Aires 123',
        hall: 'Auditorio',
        city: 1
      },
      info: 'Obra dramatica dirigida por fulano de tal, apta para todo publico. Fulano se encuentra en problemas...',
      tickets: [{type: 'Generales', price: 100, currency: 1}],
      participants: [
        {
          id: 1,
          display: 'Director',
          people: [
            {
              id: 12,
              name: 'Juan Gomez'
            }
          ]
        }, {
          id: 3,
          display: 'Elenco',
          people: [
            {
              id: 13,
              name: 'Maria Coto'
            }, {
              id: 14,
              name: 'Julio Perez'
            }
          ]
        }
      ],
      moreData: [
        {
          header: 'Propociones',
          text: 'Descuento de 50% con tarjetas Brou'
        }
      ]
    });
  }

  createEvent = (eventForm) => {
    return of({
      id: 1,
      name: 'Teatro Solis',
      address: 'Buenos aires 123'
    });
  }

  editEvent = (eventForm) => {
    return of({
      id: 1,
      name: 'Teatro Solis',
      address: 'Buenos aires 123'
    });
  }

  deleteEvent = (eventId) => {
    return of({
      id: 1,
      name: 'Teatro Solis',
      address: 'Buenos aires 123'
    });
  }
}
