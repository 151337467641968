import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Category } from '../../core/interfaces/category';
import { Place } from '../../core/interfaces/place';
import { PlacesResponse } from '../../core/interfaces/places-response';

@Injectable()
export class PlacesAdminService {
  constructor(
  ) {
  }

  getPlaces = (cityId, limit, offset): Observable<PlacesResponse> => {
    return of({
      items: [{
        id: 1,
        name: 'Teatro Solis',
        address: 'Buenos aires 123'
      }, {
        id: 2,
        name: 'La trastienda',
        address: 'Fernandez Crespo 123'
      }],
      totalCount: 2,
      firstIndex: 1,
    });
  }

  createPlace = (cityId: number, place: Place): Observable<Place> => {
    return of({
      id: 1,
      name: 'Teatro Solis',
      address: 'Buenos aires 123'
    });
  }

  updatePlace = (cityId: number, place: Place): Observable<Place> => {
    return of({
      id: 1,
      name: 'Teatro Solis',
      address: 'Buenos aires 123'
    });
  }
}
