import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiHelperService } from './api-helper.service';
import { Category } from '../interfaces/category';
import { Subcategory } from '../interfaces/subcategory';

@Injectable()
export class CategoriesService {
  constructor(
  ) {
  }

  getCategories = (): Observable<Category[]> => {
    return of([{
      id: 1,
      name: 'Teatro',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/theater_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 2,
      name: 'Musica en vivo',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/live_music_cat.jpg',
      genres: [{id: 4, name: 'Rock'}, {id: 5, name: 'Plena'}, {id: 6, name: 'Blues'}]
    }, {
      id: 3,
      name: 'Circo',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/circus_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 4,
      name: 'Karaoke',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/karaoke_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }, {
      id: 5,
      name: 'Magia',
      imageUrl: 'https://culturupublic.blob.core.windows.net/public/magic_cat.jpg',
      genres: [{id: 1, name: 'Impro'}, {id: 2, name: 'Comedia'}, {id: 3, name: 'Drama'}]
    }]);
  }
}
